import React from "react";
import Base from "./Base";

const Do = () => {
    const header = "/clients/ecu/do/header.jpeg";
    const slides = [
        "/clients/ecu/do/slide-1.png",
        "/clients/ecu/do/slide-2.png",
        "/clients/ecu/do/slide-3.png",
        "/clients/ecu/do/slide-4.png",
    ];

    return <Base header={header} slides={slides} />;
};

export default Do;
