import React from "react";
import Base from "./Base";

const Nursing = () => {
    const header = "/clients/ecu/nursing/header.jpeg";
    const slides = [
        "/clients/ecu/nursing/slide-1.png",
        "/clients/ecu/nursing/slide-2.png",
    ];

    return <Base header={header} slides={slides} />;
};

export default Nursing;
