import React from "react";
import Links from "../components/Links";

const ECU = () => {
    const links = [
        {
            link: "/ecu",
            title: "ECU",
        },
    ];

    return <Links links={links} />;
};

export default ECU;
