import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";

const Slide = styled.div`
    position: relative;
    max-height: 100%;
    overflow: hidden;
    padding-right: 3.5%;

    div {
        background: left top / contain no-repeat;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    img {
        opacity: 0;
    }
`;

const Slider = ({ slides }) => {
    return (
        <Carousel
            // centerMode={true}
            // centerSlidePercentage={85}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            renderArrowPrev={() => false}
            renderArrowNext={() => false}
        >
            {slides &&
                slides.map((image, key) => (
                    <Slide key={key}>
                        <div
                            className="bg"
                            style={{ backgroundImage: `url(${image})` }}
                        ></div>
                        <img alt={`slide-${key}`} src={image} />
                    </Slide>
                ))}
        </Carousel>
    );
};

export default Slider;
