import React from "react";
import Links from "../components/Links";

const ECU = () => {
    const links = [
        {
            link: "/ecu/nursing",
            title: "Nursing",
        },
        {
            link: "/ecu/business",
            title: "Business",
        },
        {
            link: "/ecu/engineering",
            title: "Engineering",
        },
        {
            link: "/ecu/do",
            title: "Do",
        },
    ];

    return <Links links={links} />;
};

export default ECU;
