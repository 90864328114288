import React from "react";
import Slider from "../../components/Slider";
import styled from "styled-components";

const Outer = styled.div`
    /* height: 100vh; */
    /* height: -webkit-fill-available; */
`;
const Inner = styled.div`
    /* min-height: -webkit-fill-available; */
    height: 100vh;
    display: flex;
    flex-direction: column;
`;
const Facebook = styled.img`
    width: 100%;
    height: auto;
`;
const Mask = styled.img`
    width: 100%;
    height: auto;
`;

const AdSlider = styled.div`
    padding: 0 3%;
    flex: 1 1 auto;
    overflow: hidden;

    .carousel-root {
        width: 85%;
    }

    .carousel-root,
    .carousel-slider,
    .slider-wrapper {
        overflow: visible;
    }

    .carousel-root,
    .carousel,
    .slider-wrapper,
    .slider {
        height: 100%;
    }
`;

const Header = ({ src }) => {
    return <Mask src={src} />;
};

const Footer = ({ src }) => {
    return <Mask src={src} />;
};

const Base = ({ header = false, slides, footer = false }) => {
    const facebookHeader = "/clients/ecu/facebook-header.jpeg";
    const facebookFooter = "/clients/ecu/facebook-footer.jpeg";

    return (
        <Outer>
            <Inner>
                <Facebook src={facebookHeader} />
                {header && <Header src={header} />}
                <AdSlider>
                    <Slider slides={slides} />
                </AdSlider>
                {footer && <Footer src={footer} />}
                <Facebook src={facebookFooter} />
            </Inner>
        </Outer>
    );
};

export default Base;
