import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Index from "./pages/Index";

import ECU from "./pages/ECU";
import ECUNursing from "./pages/ECU/Nursing";
import ECUBusiness from "./pages/ECU/Business";
import ECUEngineering from "./pages/ECU/Engineering";
import ECUDo from "./pages/ECU/Do";

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={<Index />} />

                    <Route path="/ecu" element={<ECU />} />
                    <Route path="/ecu/nursing" element={<ECUNursing />} />
                    <Route path="/ecu/business" element={<ECUBusiness />} />
                    <Route
                        path="/ecu/engineering"
                        element={<ECUEngineering />}
                    />
                    <Route path="/ecu/do" element={<ECUDo />} />
                </Routes>
            </div>
        </Router>
    );
}
export default App;
